import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import '../assets/scss/main.scss';
import './../assets/fonts/circular-std-book/style.scss';

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>
        {children}
      </div>
    );
  }
}
